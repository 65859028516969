import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  fetchTournaments,
  tournamentsApiSlice
} from '../api';
import {
  type TournamentsPayload,
  type TournamentsState,
} from './types';

const initialState: TournamentsState = {
  isLoading: true,
  publicTournaments: [],
  tournamentRankings: [],
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      fetchTournaments.pending,
      (state: TournamentsState) => { state.isLoading = true; }
    );

    builder.addCase(
      fetchTournaments.fulfilled,
      (state: TournamentsState, action: PayloadAction<TournamentsPayload>) => {
        state.isLoading = false;
        state.publicTournaments = [...action.payload.tournaments]
          .filter(tournament => {
            const vipStatus = action.payload.vipStatus;
            const { includedTags, excludedTags } = tournament;

            if (!vipStatus && (includedTags || excludedTags)) return false;
            if (includedTags && includedTags.length > 0 && !includedTags.includes(vipStatus!.toUpperCase())) return false;
            if (excludedTags && excludedTags.length > 0 && excludedTags.includes(vipStatus!.toUpperCase())) return false;

            return true;
          })
          .sort((a, b) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
          );
      }
    );

    builder.addMatcher(
      tournamentsApiSlice.endpoints.getPublicTournaments.matchFulfilled,
      (state, action) => {
        state.publicTournaments = action.payload;
      });

    builder.addMatcher(
      tournamentsApiSlice.endpoints.getTournamentRanking.matchFulfilled,
      (state, action) => {
        state.tournamentRankings = action.payload;
      });
  },
});

export const tournamentsReducer = tournamentsSlice.reducer;
