import {
  FC
} from 'react';
import {
  Stack,
  StackProps,
  Box,
  Typography
} from '@mui/joy';

export type PaymentMethodsCardProps = StackProps & {
  label: string;
};

export const PaymentMethodsCard: FC<PaymentMethodsCardProps> = ({
  label,
  children,
  ...props
}) => (
  <Stack gap='8px' {...props}>
    <Typography
      sx={{
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px'
      }}>
      {label}
    </Typography>
    <Box
      sx={({ breakpoints }) => ({
        display: 'grid',
        gap: '8px',
        gridTemplateColumns: '1fr 1fr',
        gridAutoRows: 'minmax(156px, 156px)',
        justifyItems: 'stretch',
        width: '100%',
        [breakpoints.down(491)]: { height: '100%' },
        [breakpoints.down(441)]: { gridTemplateColumns: '1fr' }
      })}>
      {children}
    </Box>
  </Stack>
)