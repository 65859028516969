import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  coinPackagesSlice
} from './slice';
import {
  type CoinPackage
} from '../api'
import {
  type CoinPackagesState,
} from './types';

type RootState = {
  [coinPackagesSlice.name]: CoinPackagesState;
};

export const selectCoinPackages = (state: RootState): Array<CoinPackage> => (
  state[coinPackagesSlice.name].packages ?? []
);

export const useCoinPackagesSelector: TypedUseSelectorHook<{
  [coinPackagesSlice.name]: CoinPackagesState;
}> = useSelector;