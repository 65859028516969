import {
  FC
} from 'react';
import {
  Stack,
  Typography,
  Divider,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  CoinRedemptionRulesInfo
} from './coin-redemption-rules-info.component';

export type RedeemableBalanceProps = {
  withdrawableBalance?: number;
  bonusBalance?: number;
};

export const RedeemableBalance: FC<RedeemableBalanceProps> = ({
  withdrawableBalance,
  bonusBalance
}) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(360));
  return (
    <Stack
      direction='row'
      justifyContent='space-around'
      alignItems='center'
      sx={({ breakpoints, palette }) => ({
        backgroundColor: palette.common[900],
        borderColor: palette.common[925],
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 8,
        height: 88,
        paddingInline: 2.5,
        paddingBlock: 2,
        [breakpoints.down(360)]: {
          flexDirection: 'column',
          height: '100%'
        }
      })}>
      <Stack
        direction='column'
        gap={1}>
        <Typography
          sx={({ palette }) => ({
            color: palette.common[150],
            textAlign: 'center',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}>
          Redeemable
        </Typography>
        <Typography
          sx={({ palette }) => ({
            color: palette.common.white,
            textAlign: 'center',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
          })}>
          {withdrawableBalance}&nbsp;SC
        </Typography>
      </Stack>
      <Divider
        orientation={
          isSmallMobile
            ? 'horizontal'
            : 'vertical'
        }
        sx={({ breakpoints }) => ({
          marginBlock: 'auto',
          height: 24,
          [breakpoints.down(360)]: {
            marginInline: 'auto',
            marginBlock: 1,
            height: 2,
            width: 24,
          }
        })}
      />
      <Stack
        direction='column'
        gap={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'>
          <Typography
            sx={({ palette }) => ({
              color: palette.common[150],
              textAlign: 'center',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
            })}>
            PlayThrough
          </Typography>
          <CoinRedemptionRulesInfo />
        </Stack>
        <Typography
          sx={({ palette }) => ({
            color: palette.common.white,
            textAlign: 'center',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
          })}>
          {bonusBalance}&nbsp;SC
        </Typography>
      </Stack>
    </Stack>
  );
};