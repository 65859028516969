import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  coinPackagesApiSlice,
} from '../api';
import {
  type CoinPackagesState,
} from './types';

const initialState: CoinPackagesState = {
  packages: [],
};

export const coinPackagesSlice = createSlice({
  name: 'coin-package',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      coinPackagesApiSlice.endpoints.getPackagesFromOmega.matchFulfilled,
      (state: CoinPackagesState, action: PayloadAction<any>) => {
        state.packages = action.payload.data;
      }
    );
  },
});

export const coinPackagesReducer = coinPackagesSlice.reducer;