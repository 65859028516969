import compose from 'compose-function';
import {
  withSignInModal
} from './with-sign-in-modal';
import {
  withSignUpModal
} from './with-sign-up-modal';
import {
  withResetPasswordModal
} from './with-reset-password-modal';
import {
  withMessagesModal
} from './with-messages-modal';
import {
  withBuyCoinsModal
} from './with-buy-coins-modal';
import {
  withCoinRedemptionModal
} from './with-coin-redemption-modal';

export const withModals = compose(
  withSignInModal,
  withSignUpModal,
  withResetPasswordModal,
  withMessagesModal,
  withCoinRedemptionModal,
  withBuyCoinsModal,
);