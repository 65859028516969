import { mapper } from '@shared/lib';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { UserRegistrationStatusEnum } from '@shared/types';
import { UserKycStatusEnum } from '@entities/session';

export type EditableFullProfile = {
  firstName: string;
  lastName: string;
  nickname: string;
  email?: string;
  phone: string;
  birthDate: Date;
  address: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  registrationStatus: UserRegistrationStatusEnum;
  kycStatus: UserKycStatusEnum;
};

type MapEditableFullProfile = (data: any) => EditableFullProfile;
export const mapEditableFullProfile: MapEditableFullProfile = mapper([
  { name: 'firstName', from: 'firstName' },
  { name: 'lastName', from: 'lastName' },
  { name: 'nickname', from: 'nickname' },
  {
    name: 'email',
    from: 'email',
    transform: (email: Maybe<Email>) => {
      if (!email || email?.includes('@email')) return "";
      return email;
    },
  },
  {
    name: 'phone',
    from: 'phone'
  },
  {
    name: 'birthDate',
    from: 'birthDate',
    transform: (birthDate: Maybe<string | Date>) => {
      if (birthDate && birthDate instanceof Date) return birthDate;
      if (birthDate && typeof birthDate === 'string') {
        dayjs.extend(customParseFormat);
        return dayjs(birthDate, 'DD-MM-YYYY HH:mm:ss').toDate();
      }
      return dayjs()
        .subtract(21, 'year')
        .subtract(1, 'day')
        .toDate();
    },
  },
  { name: 'address', from: 'address' },
  {
    name: 'country',
    from: 'country',
    transform: (country: Maybe<string>) => {
      return country ?? 'US';
    },
  },
  { name: 'province', from: 'province' },
  { name: 'city', from: 'city' },
  { name: 'postalCode', from: 'postalCode' },
  { name: 'kycStatus', from: 'kycStatus' },
  { name: 'registrationStatus', from: 'registrationStatus' },
]);
