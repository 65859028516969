import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';

export type RedeemCoinsState = {
  openConfirmCoinRedemptionWorldPayModal: boolean;
  openConfirmCoinRedemptionPaymentIQModal: boolean;
  coinRedeem: string | number;
};

const initialState: RedeemCoinsState = {
  openConfirmCoinRedemptionWorldPayModal: false,
  openConfirmCoinRedemptionPaymentIQModal: false,
  coinRedeem: 0
};

export const redeemCoinsSlice = createSlice({
  name: 'redeem-coins',
  initialState,
  reducers: {
    resetRedeemCoins: (state: RedeemCoinsState) => {
      Object.assign(state, initialState);
    },
    storeCoinRedeem: (state: RedeemCoinsState, action: PayloadAction<string | number>) => {
      state.coinRedeem = action.payload;
    },
    openConfirmCoinRedemptionWorldPayModal: (state: RedeemCoinsState) => {
      state.openConfirmCoinRedemptionWorldPayModal = true;
    },
    closeConfirmCoinRedemptionWorldPayModal: (state: RedeemCoinsState) => {
      state.openConfirmCoinRedemptionWorldPayModal = false;
    },
    openConfirmCoinRedemptionPaymentIQModal: (state: RedeemCoinsState) => {
      state.openConfirmCoinRedemptionPaymentIQModal = true;
    },
    closeConfirmCoinRedemptionPaymentIQModal: (state: RedeemCoinsState) => {
      state.openConfirmCoinRedemptionPaymentIQModal = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState);
  },
});

export const {
  resetRedeemCoins,
  storeCoinRedeem,
  openConfirmCoinRedemptionPaymentIQModal,
  closeConfirmCoinRedemptionPaymentIQModal,
  openConfirmCoinRedemptionWorldPayModal,
  closeConfirmCoinRedemptionWorldPayModal
} = redeemCoinsSlice.actions;

export const redeemCoinsReducer = redeemCoinsSlice.reducer;