import {
  isDefined,
  mapper
} from '@shared/lib';
import {
  type PaymentMethod,
  type PaymentMethodOrderSet,
} from '../model';
import {
  mapPaymentMethod
} from './map-payment-method';

type MapPaymentMethodOrderSet = (data: any) => PaymentMethodOrderSet;
export const mapPaymentMethodOrderSet: MapPaymentMethodOrderSet = mapper([
  { name: 'id', from: 'id' },
  { name: 'category', from: 'attributes.category' },
  { name: 'type', from: 'attributes.type' },
  {
    name: 'recommended',
    from: 'attributes.recommended.data',
    transform: (recommended: Array<PaymentMethod>) => {
      if (isDefined(recommended)) return recommended.map(mapPaymentMethod);
      return [];
    }
  },
  {
    name: 'alternative',
    from: 'attributes.alternative.data',
    transform: (alternative: Array<PaymentMethod>) => {
      if (isDefined(alternative)) return alternative.map(mapPaymentMethod);
      return [];
    }
  },
  {
    name: 'allowedTags',
    from: 'attributes.allowedTags',
    transform: (allowedTags: Maybe<string>) => {
      if (allowedTags) return allowedTags.split(',');
      return null;
    }
  },
  {
    name: 'forbiddenTags',
    from: 'attributes.forbiddenTags',
    transform: (forbiddenTags: Maybe<string>) => {
      if (forbiddenTags) return forbiddenTags.split(',');
      return null;
    }
  },
]);
