import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  paymentsSlice
} from './slice';
import {
  type CheckoutResultWorldPay,
  type PaymentsState,
  PaymentMethod
} from './types';

type RootState = {
  [paymentsSlice.name]: PaymentsState;
};

export const selectOpenCheckoutFailedWorldPayModal = (state: RootState): boolean => (
  state[paymentsSlice.name].openCheckoutFailedWorldPayModal
);

export const selectOpenCheckoutSuccessWorldPayModal = (state: RootState): boolean => (
  state[paymentsSlice.name].openCheckoutSuccessWorldPayModal
);

export const selectCheckoutResultWorldPay = (state: RootState): Maybe<CheckoutResultWorldPay> => (
  state[paymentsSlice.name].checkoutResultWorldPay
);

export const selectActionPurpose = (actionPurpose: string) => (state: RootState): boolean => (
  state[paymentsSlice.name].actionPurpose === actionPurpose
);

export const selectOpenPaymentMethodsSelectorModal = (state: RootState): boolean => (
  state[paymentsSlice.name].openPaymentMethodsSelectorModal
);

export const selectPaymentMethodOrderSetType = (state: RootState) => (
  state[paymentsSlice.name].type
);

export const selectPaymentMethodOrderSets = (userTags: Maybe<Array<string>>) => (state: RootState) => {
  if (!userTags) {
    return state[paymentsSlice.name].paymentMethodOrderSet.find(item =>
      (!item.allowedTags || item.allowedTags.length === 0) &&
      (!item.forbiddenTags || item.forbiddenTags.length === 0) &&
      item.type === state[paymentsSlice.name].type
    ) || null;
  }

  return state[paymentsSlice.name].paymentMethodOrderSet.find(item => {
    if (item.type !== state[paymentsSlice.name].type) {
      return false;
    }

    if (item.allowedTags && item.allowedTags.length > 0) {
      if (!item.allowedTags.some(tag => userTags.includes(tag))) {
        return false;
      }
    }

    if (item.forbiddenTags && item.forbiddenTags.some(tag => userTags.includes(tag))) {
      return false;
    }

    return true;
  }) || null;
};

export const selectCurrentPaymentMethod = (state: RootState): Maybe<PaymentMethod> => (
  state[paymentsSlice.name].paymentMethod
);

export const selectRecentlyUsedPaymentMethod = (state: RootState): Maybe<PaymentMethod> => (
  state[paymentsSlice.name].recentlyUsedPaymentMethod
);

export const usePaymentsSelector: TypedUseSelectorHook<{
  [paymentsSlice.name]: PaymentsState;
}> = useSelector;