import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  type RedeemCoinsState,
  redeemCoinsSlice
} from './slice';

type RootState = {
  [redeemCoinsSlice.name]: RedeemCoinsState;
};

export const selectOpenConfirmCoinRedemptionPaymentIQModal = (state: RootState): boolean => (
  state[redeemCoinsSlice.name].openConfirmCoinRedemptionPaymentIQModal
);

export const selectOpenConfirmCoinRedemptionWorldPayModal = (state: RootState): boolean => (
  state[redeemCoinsSlice.name].openConfirmCoinRedemptionWorldPayModal
);

export const selectCoinRedeem = (state: RootState): number => (
  state[redeemCoinsSlice.name].coinRedeem as unknown as number
);

export const useRedeemCoinsSelector: TypedUseSelectorHook<{
  [redeemCoinsSlice.name]: RedeemCoinsState;
}> = useSelector;