import {
  ComponentType,
  Fragment,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  ContactUsLink,
  TermsOfUseLink,
  useToaster
} from '@shared/ui';
import {
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import {
  PaymentMethodKeyEnum,
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  UserKycStatusEnum,
  selectIsAuthenticated,
  selectKycStatus,
  selectRegistrationStatus,
  selectUserBalance,
  selectUserTags,
  useSessionSelector
} from '@entities/session';
import {
  PaymentMethodsSelectorModal,
  CheckoutFailedWorldPayModal,
  CheckoutSuccessWorldPayModal,
  closeCheckoutFailedWorldPayModal,
  openCheckoutFailedWorldPayModal,
  closeCheckoutSuccessWorldPayModal,
  closePaymentMethodsSelectorModal,
  openCheckoutSuccessWorldPayModal,
  openPaymentMethodsSelectorModal,
  selectOpenPaymentMethodsSelectorModal,
  selectPaymentMethodOrderSetType,
  selectActionPurpose,
  storeRecentlyUsedPaymentMethod,
  selectCurrentPaymentMethod,
  usePaymentsSelector,
  PaymentMethodOrderSetTypeEnum,
  type CheckoutResultWorldPay,
  type PaymentMethod,
} from '@entities/payments';
import {
  CoinRedemptionModal,
  ConfirmCoinRedemptionWorldPayModal,
  ConfirmCoinRedemptionPaymentIQModal,
  PleaseConfirmYourIdentityModal,
  storeCoinRedeem,
  openConfirmCoinRedemptionPaymentIQModal,
  openConfirmCoinRedemptionWorldPayModal,
  selectOpenConfirmCoinRedemptionPaymentIQModal,
  selectOpenConfirmCoinRedemptionWorldPayModal,
  useRedeemCoinsSelector,
} from '@features/redeem-coins';
import {
  useSendDataToGtm
} from '@features/collect-analytics';

export const withCoinRedemptionModal = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal, openModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const userBalance = useSessionSelector(selectUserBalance);
    const userTags = useSessionSelector(selectUserTags);

    const isOpenConfirmCoinRedemptionWorldPayModal = useRedeemCoinsSelector(selectOpenConfirmCoinRedemptionWorldPayModal);
    const isOpenConfirmCoinRedemptionPaymentIQModal = useRedeemCoinsSelector(selectOpenConfirmCoinRedemptionPaymentIQModal);
    const isOpenPaymentMethodsSelector = usePaymentsSelector(selectOpenPaymentMethodsSelectorModal);
    const paymentMethodOrderSetType = usePaymentsSelector(selectPaymentMethodOrderSetType);
    const currentPaymentMethod = usePaymentsSelector(selectCurrentPaymentMethod);
    const isRedeemActionPurpose = usePaymentsSelector(selectActionPurpose('redeem'));
    const redeemCoinsPopup = useQueryParamModalVisibility('redeem');

    const { sendDataToGtm } = useSendDataToGtm();

    const allowRedeemCoins = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const isOpenCoinRedemptionModal = useMemo(
      () => redeemCoinsPopup && isAuthenticated && allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    const isOpenPleaseConfirmYourIdentityModal = useMemo(
      () => redeemCoinsPopup && isAuthenticated && !allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    const isOpenPaymentMethodsSelectorModal = useMemo(
      () => (
        isOpenPaymentMethodsSelector
        && paymentMethodOrderSetType === PaymentMethodOrderSetTypeEnum.Withdrawal
        && isAuthenticated
      ),
      [
        isOpenPaymentMethodsSelector,
        paymentMethodOrderSetType,
        isAuthenticated,
      ]
    );

    useEffect(() => {
      if (!isAuthenticated && redeemCoinsPopup) {
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 5000
        });
      }
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('redeem');
    };

    const handleCoinRedeem = (amount: string | number): void => {
      closeModal('redeem');
      dispatch(storeCoinRedeem(amount));
      dispatch(openPaymentMethodsSelectorModal(PaymentMethodOrderSetTypeEnum.Withdrawal));
    };

    const handleRedeemStart = (amount: string | number): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_start',
          withdrawalAmount: amount,
          withdrawalCurrency: 'USD',
          withdrawalCount: '1',
          paymentMethod: 'Trustly'
        }
      })
    };

    const handleRedeemSuccess = (redeemResult?: CheckoutResultWorldPay): void => {
      if (redeemResult) {
        dispatch(openCheckoutSuccessWorldPayModal({
          actionPurpose: 'redeem',
          result: redeemResult
        }));

        if (currentPaymentMethod) {
          dispatch(storeRecentlyUsedPaymentMethod(currentPaymentMethod));
        }
      }
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_success',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleRedeemFail = (redeemResult?: CheckoutResultWorldPay): void => {
      if (redeemResult) {
        dispatch(openCheckoutFailedWorldPayModal({
          actionPurpose: 'redeem',
          result: redeemResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_fail',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleCloseWorldPayCheckoutSuccessPopup = (): void => {
      dispatch(closeCheckoutSuccessWorldPayModal());
    };

    const handleCloseWorldPayCheckoutFailedPopup = (): void => {
      dispatch(closeCheckoutFailedWorldPayModal());
    };

    const handleWorldPayCheckoutTryAgain = (): void => {
      dispatch(closeCheckoutFailedWorldPayModal());
      openModal('redeem');
    };

    const handleSelectPaymentMethods = (paymentMethod: PaymentMethod): void => {
      const triggerPaymentCheckoutModal = ({
        [PaymentMethodKeyEnum.WorldPayCNPCard]: () => {
          dispatch(openConfirmCoinRedemptionWorldPayModal());
          dispatch(closePaymentMethodsSelectorModal());
        },
        [PaymentMethodKeyEnum.PaymentIQ]: () => {
          dispatch(openConfirmCoinRedemptionPaymentIQModal());
          dispatch(closePaymentMethodsSelectorModal());
        },
        [PaymentMethodKeyEnum.Unipaas]: null
      })[paymentMethod.key];
      triggerPaymentCheckoutModal?.();
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />

        <CoinRedemptionModal
          open={isOpenCoinRedemptionModal}
          withdrawableBalance={userBalance?.withdrawableBalanceNumber ?? 0}
          bonusBalance={userBalance?.bonusBalanceNumber ?? 0}
          onClose={handleClose}
          onCoinRedeem={handleCoinRedeem}
        />

        <PleaseConfirmYourIdentityModal
          open={isOpenPleaseConfirmYourIdentityModal}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Redeem a prize
            </Typography>
          )}
          onClose={handleClose}
        />

        {isOpenPaymentMethodsSelectorModal && (
          <PaymentMethodsSelectorModal
            open={isOpenPaymentMethodsSelectorModal}
            key={PaymentMethodOrderSetTypeEnum.Withdrawal}
            type={PaymentMethodOrderSetTypeEnum.Withdrawal}
            userTags={userTags}
            onSelectPaymentMethod={handleSelectPaymentMethods}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                How do you like to pay?
              </Typography>
            )}
          />
        )}

        {isOpenConfirmCoinRedemptionPaymentIQModal && (
          <ConfirmCoinRedemptionPaymentIQModal
            open={isOpenConfirmCoinRedemptionPaymentIQModal}
            onRedeemFail={handleRedeemFail}
            onRedeemStart={handleRedeemStart}
            onRedeemSuccess={handleRedeemSuccess}
          />
        )}

        {isOpenConfirmCoinRedemptionWorldPayModal && (
          <ConfirmCoinRedemptionWorldPayModal
            open={isOpenConfirmCoinRedemptionWorldPayModal}
            onRedeemFail={handleRedeemFail}
            onRedeemStart={handleRedeemStart}
            onRedeemSuccess={handleRedeemSuccess}
          />
        )}

        {isRedeemActionPurpose && (
          <Fragment>
            <CheckoutSuccessWorldPayModal
              onClose={handleCloseWorldPayCheckoutSuccessPopup}
              receiptFooterSlot={
                <Typography
                  sx={({ palette }) => ({
                    marginTop: 3,
                    fontSize: 16,
                    color: palette.common[100],
                    textAlign: 'center'
                  })}>
                  Your redemption request has been submitted <br />
                  successfully. It takes 1-2 business days to <br />
                  process your order.&nbsp;
                  <TermsOfUseLink sx={{ fontSize: 16 }} onClick={handleCloseWorldPayCheckoutSuccessPopup} />
                  &nbsp;apply.&nbsp;
                  <ContactUsLink sx={{ fontSize: 16 }} onClick={handleCloseWorldPayCheckoutSuccessPopup}>
                    For help, please contact our support team.
                  </ContactUsLink>
                </Typography>
              }
            />
            <CheckoutFailedWorldPayModal
              onClose={handleCloseWorldPayCheckoutFailedPopup}
              onTryAgain={handleWorldPayCheckoutTryAgain}
              onBackupPayment={handleSelectPaymentMethods}
            />
          </Fragment>
        )}
      </Fragment>
    );
  };