import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Modal,
  ModalDialog,
  ModalDialogProps,
  Stack,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  CloseIcon,
  IconButton,
} from '@shared/ui';
import {
  isDefined
} from '@shared/lib';
import {
  useGetPaymentMethodOrderSetsQuery,
} from '../api';
import {
  closePaymentMethodsSelectorModal,
  selectPaymentMethod,
  PaymentMethod,
  PaymentMethodOrderSetTypeEnum,
  selectPaymentMethodOrderSets,
  selectRecentlyUsedPaymentMethod,
  usePaymentsSelector
} from '../model';
import {
  PaymentMethodsCard
} from './payment-methods-card.component';
import {
  PaymentMethodButton
} from './payment-method-button.component';

export type PaymentMethodsSelectorModalProps = Pick<ModalDialogProps, 'sx'> & {
  open: boolean;
  titleSlot?: ReactNode;
  userTags?: Maybe<Array<string>>;
  type: PaymentMethodOrderSetTypeEnum
  onClose?(): void;
  onSelectPaymentMethod?(paymentMethod: PaymentMethod): void;
};

export const PaymentMethodsSelectorModal: FC<PaymentMethodsSelectorModalProps> = ({
  open,
  titleSlot,
  userTags,
  type,
  onClose,
  onSelectPaymentMethod,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));
  const dispatch = useDispatch();

  const paymentMethodOrderSets = usePaymentsSelector(selectPaymentMethodOrderSets(userTags));
  const recentlyUsedPaymentMethod = usePaymentsSelector(selectRecentlyUsedPaymentMethod);

  const { isLoading, refetch } = useGetPaymentMethodOrderSetsQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSelectPaymentMethod = useCallback((paymentMethod: PaymentMethod) => () => {
    dispatch(closePaymentMethodsSelectorModal());
    dispatch(selectPaymentMethod(paymentMethod));
    onSelectPaymentMethod?.(paymentMethod);
    onClose?.();
  }, [
    dispatch,
    onSelectPaymentMethod,
    onClose
  ]);

  const handleClose = (): void => {
    onClose?.();
    dispatch(closePaymentMethodsSelectorModal());
  };

  return isLoading ? null : (
    <Modal open={open}>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: 'fit-content',
          maxWidth: 488,
          backgroundColor: palette.common[475],
          [breakpoints.down(491)]: {
            height: '100%',
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}
        {...props}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={
              isDefined(titleSlot)
                ? 'space-between'
                : 'flex-end'
            }
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            {titleSlot}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={({ breakpoints }) => ({
              overflow: 'auto',
              maxHeight: `calc(100vh - 224px)`,
              rowGap: '32px',
              [breakpoints.down(491)]: {
                height: '100%',
                maxHeight: '100%',
              },
            })}>

            {recentlyUsedPaymentMethod && (
              <PaymentMethodsCard label='Recently Used'>
                <PaymentMethodButton
                  key={recentlyUsedPaymentMethod.id}
                  hint={recentlyUsedPaymentMethod.displayName}
                  iconSrc={recentlyUsedPaymentMethod.iconSrc}
                  onClick={handleSelectPaymentMethod(recentlyUsedPaymentMethod)}
                />
              </PaymentMethodsCard>
            )}

            {paymentMethodOrderSets?.recommended && (
              <PaymentMethodsCard label='Recommended'>
                {paymentMethodOrderSets?.recommended.map(paymentMethod => (
                  <PaymentMethodButton
                    key={paymentMethod.id}
                    hint={paymentMethod.displayName}
                    iconSrc={paymentMethod.iconSrc}
                    onClick={handleSelectPaymentMethod(paymentMethod)}
                  />
                ))}
              </PaymentMethodsCard>
            )}

            {paymentMethodOrderSets?.alternative && (
              <PaymentMethodsCard label='Alternative Payments'>
                {paymentMethodOrderSets?.alternative.map(paymentMethod => (
                  <PaymentMethodButton
                    key={paymentMethod.id}
                    hint={paymentMethod.displayName}
                    iconSrc={paymentMethod.iconSrc}
                    onClick={handleSelectPaymentMethod(paymentMethod)}
                  />
                ))}
              </PaymentMethodsCard>
            )}
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};