import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  addFavoriteGame,
  favoriteGamesApiSlice,
  gamesListApiSlice,
  removeFavoriteGame
} from '../api';
import {
  mapFavoriteGames
} from '../lib';
import {
  type GamesState
} from './types';

export const initialState: GamesState = {
  categories: [],
  myGamesDataFromOmegaApi: [],
  isFavoriteLoadingFromOmega: false,
  gamesPageCategoryIds: [],
  lastUpdated: null,
};

export const gamesSlice = createSlice({
  name: 'games-list',
  initialState,
  reducers: {
    setGamesPageCategoryIds: (
      state: GamesState,
      action: PayloadAction<Array<number>>
    ) => {
      state.gamesPageCategoryIds = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(addFavoriteGame.fulfilled, state => {
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(removeFavoriteGame.fulfilled, state => {
      state.lastUpdated = new Date().toISOString();
    });
    builder.addMatcher(
      gamesListApiSlice.endpoints.getGamesCategories.matchFulfilled,
      (state: GamesState, action: PayloadAction<any>) => {
        state.categories = action.payload?.categories;
      }
    );
    builder.addMatcher(
      favoriteGamesApiSlice.endpoints.getMyFavoriteGamesFromOmega.matchFulfilled,
      (state: GamesState, action: PayloadAction<any>) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          const myGamesDataFromOmegaApi = mapFavoriteGames(action.payload).myGamesDataFromOmegaApi;
          state.myGamesDataFromOmegaApi = myGamesDataFromOmegaApi;
        }
      }
    );
  },
});

export const { setGamesPageCategoryIds } = gamesSlice.actions;
export const gamesReducer = gamesSlice.reducer;
