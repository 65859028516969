import {
  type OmegaApiResponse
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';

export type UpdatePaymentBillingInfo = {
  billingFirstName: string;
  billingLastName: string;
  billingAddress1: string;
  billingCity: string;
  billingPostalCode: string;
  billingState: string;
  billingCountry: string;
  billingEmail: string;
  billingPhone: string;
}

export type UpdatePaymentFromStoredCardParams = UpdatePaymentBillingInfo & {
  paymentMethod: PaymentMethodKeyEnum;
  amount: number;
  paymentId: string;
  paymentInformation: string;
};

export type UpdatePaymentFromCheckoutFormParams = UpdatePaymentBillingInfo & {
  paymentMethod: PaymentMethodKeyEnum;
  amount: number;
  paymentId: string;
  registrationId: string;
  expiryDate: string;
  last4Digit: string;
  saveCardOnFile: boolean;
  cardType: 'MC' | 'DI' | 'VI'; // MC - MasterCard, DI - Discover, VI - Visa
};

export type UpdatePaymentParams = UpdatePaymentFromStoredCardParams | UpdatePaymentFromCheckoutFormParams;

export type GetPaymentInfoParams = {
  paymentMethods: PaymentMethodKeyEnum;
};

export enum CardDetailsKeyEnum {
  BillingCountry = 'BILLING_COUNTRY',
  BillingState = 'BILLING_STATE',
  BillingCity = 'BILLING_CITY',
  BillingPostalCode = 'BILLING_POSTAL_CODE',
  ExpiryDate = 'EXPIRY_DATE',
  Name = 'NAME',
  Token = 'TOKEN',
  Last4Digit = 'LAST_4_DIGIT',
  Type = 'TYPE'
};

export type CardDetailsInfo = {
  id: number;
  paymentInformationId: number;
  key: CardDetailsKeyEnum;
  value: string;
};

export type CardInfo = {
  id: number;
  partyId: number;
  paymentMethods: Array<PaymentMethodKeyEnum>;
  priority: number;
  details: Array<CardDetailsInfo>;
};

export type GetPaymentInfoResponse = OmegaApiResponse & {
  data: Array<CardInfo>;
};

export type UpdatePaymentResponse = OmegaApiResponse & {
  referenceNo?: string;
  paymentId?: string;
};