import {
  FC
} from 'react';
import {
  Typography
} from '@mui/joy';
import {
  Link
} from '@shared/ui';

type AccountLinkProps = {
  onClick?: () => void;
};

const AccountLink: FC<AccountLinkProps> = ({ onClick }) => (
  <Link
    color='primary'
    href='/account'
    underline='none'
    onClick={onClick}
    sx={{
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    }}>
    profile
  </Link>
);

export type PleaseConfirmMessageProps = {
  onClick?: () => void;
};

export const PleaseConfirmMessage: FC<PleaseConfirmMessageProps> = ({ onClick }) => {
  return (
    <Typography
      sx={{
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
      }}>
      Please confirm your identity by completing your player&nbsp;
      <AccountLink onClick={onClick} />&nbsp;
      as seen on your ID and uploading ID for verification
    </Typography>
  );
};