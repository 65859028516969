import {
  PAYMENT_INFO_TAG,
  baseApi,
  baseOmegaApi
} from '@shared/api';
import {
  GetPaymentInfoParams,
  GetPaymentInfoResponse,
  type UpdatePaymentParams,
  type UpdatePaymentResponse,
} from './types';

export const paymentsApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getPaymentMethods: build.query<any, void>({
      query: () => ({ url: '/api/payment-methods' }),
      extraOptions: { auth: false, showLoader: true },
    }),
    getPaymentMethodOrderSets: build.query<any, void>({
      query: () => ({ url: '/api/payment-method-order-sets' }),
      extraOptions: { auth: false, showLoader: true },
    })
  })
});

export const paymentsOmegaApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: build => ({
    getPaymentInfo: build.query<GetPaymentInfoResponse, GetPaymentInfoParams>({
      query: params => ({ url: 'ips/getPaymentInformation', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    updatePayment: build.query<UpdatePaymentResponse, UpdatePaymentParams>({
      query: params => ({ url: 'ips/updatePayment', params }),
      providesTags: (result) => result ? [PAYMENT_INFO_TAG] : [],
      extraOptions: { auth: true, showLoader: true }
    })
  }),
});

export const {
  useGetPaymentMethodOrderSetsQuery,
  useLazyGetPaymentMethodOrderSetsQuery,
  useGetPaymentMethodsQuery,
  useLazyGetPaymentMethodsQuery,
} = paymentsApiSlice;

export const {
  useLazyUpdatePaymentQuery,
  useUpdatePaymentQuery,
  useLazyGetPaymentInfoQuery,
  useGetPaymentInfoQuery,
} = paymentsOmegaApiSlice;
