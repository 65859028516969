import { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/joy';
import { type Transaction } from '../model';

export type TransactionCardProps = Omit<Transaction, 'type' | 'amount'> & {
  labelForTransactionType: string;
  amount: string;
};

export const TransactionCard: FC<TransactionCardProps> = ({
  id,
  dateTime,
  labelForTransactionType,
  gameName,
  paymentSubMethod,
  amount,
  product
}) => {
  return (
    <Stack
      direction='column'
      sx={({ palette }) => ({
        width: '100%',
        overflow: 'hidden',
        borderRadius: 8,
        background: palette.common[475],
        borderColor: palette.common[925],
        borderWidth: 1,
        borderStyle: 'solid',
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          width: '100%',
          height: 44,
          paddingInlineStart: 2
        }}>
        <Typography
          sx={({ palette }) => ({
            width: 119,
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            color: palette.common[150]
          })}>
          Transaction ID
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common
          })}>
          {id}
        </Typography>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ width: '100%' }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          width: '100%',
          height: 44,
          paddingInlineStart: 2
        }}>
        <Typography
          sx={({ palette }) => ({
            width: 119,
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            color: palette.common[150]
          })}>
          Date
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common
          })}>
          {dateTime}
        </Typography>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ width: '100%' }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          width: '100%',
          height: 44,
          paddingInlineStart: 2
        }}>
        <Typography
          sx={({ palette }) => ({
            width: 119,
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            color: palette.common[150]
          })}>
          Type
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common
          })}>
          {labelForTransactionType}
        </Typography>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ width: '100%' }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          width: '100%',
          height: 44,
          paddingInlineStart: 2
        }}>
        <Typography
          sx={({ palette }) => ({
            width: 119,
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            color: palette.common[150]
          })}>
          Product
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common
          })}>
          {gameName ?? product}
        </Typography>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ width: '100%' }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          width: '100%',
          height: 44,
          paddingInlineStart: 2
        }}>
        <Typography
          sx={({ palette }) => ({
            width: 119,
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            color: palette.common[150]
          })}>
          Amount
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common
          })}>
          {amount}
        </Typography>
      </Stack>
    </Stack>
  );
};