import {
  FC,
  ReactNode,
} from 'react';
import {
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  useTheme as useMaterialTheme,
  useMediaQuery
} from '@mui/material';
import {
  CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon
} from '@mui/icons-material';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  usePaymentsSelector,
  selectCheckoutResultWorldPay,
  selectOpenCheckoutSuccessWorldPayModal
} from '../model';

export type CheckoutSuccessWorldPayModalProps = {
  receiptFooterSlot?: ReactNode;
  onClose?(): void;
};

export const CheckoutSuccessWorldPayModal: FC<CheckoutSuccessWorldPayModalProps> = ({
  receiptFooterSlot,
  onClose
}) => {
  const joyTheme = useJoyTheme();
  const materialTheme = useMaterialTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down(490));
  const checkoutResult = usePaymentsSelector(selectCheckoutResultWorldPay);
  const open = usePaymentsSelector(selectOpenCheckoutSuccessWorldPayModal);
  return (
    <Modal open={open} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 578,
          backgroundColor: palette.common[475],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction='column'
            justifyContent='flex-start'
            width='100%'
            height='100%'
            rowGap={5}>

            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              rowGap={3}
              sx={({ breakpoints }) => ({
                rowGap: 3,
                paddingInline: 1,
                [breakpoints.down(490)]: {
                  paddingTop: 4,
                  rowGap: 4,
                }
              })}>

              <CheckCircleOutlineRoundedIcon
                sx={{
                  fontSize: 112,
                  color: joyTheme.palette.common[825]
                }}
              />

              <Typography
                sx={({ palette }) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: 44,
                  fontSize: 22,
                  fontWeight: 'bold',
                  fontStyle: 'normal',
                  lineHeight: '20px',
                  color: palette.common.white,
                  backgroundColor: palette.common[900],
                  borderRadius: 8,
                })}>
                {formatCurrency(checkoutResult?.amount ?? 0)}
              </Typography>
            </Stack>

            <Stack
              width='100%'
              paddingInline={1}>
              {checkoutResult?.cardNumber && (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Card number
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.cardNumber}
                  </Typography>
                </Stack>
              )}

              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={({ palette }) => ({
                  paddingBlock: 1,
                  width: '100%',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: palette.common[925],
                })}>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  Purchase amount
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  {formatCurrency(checkoutResult?.amount ?? 0)}
                </Typography>
              </Stack>

              {checkoutResult?.referenceId && (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Reference ID
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.referenceId}
                  </Typography>
                </Stack>
              )}

              {checkoutResult?.paymentId && (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Payment ID
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.paymentId}
                  </Typography>
                </Stack>
              )}

              {receiptFooterSlot ? receiptFooterSlot : (
                <Stack alignItems='center' justifyContent='center'>
                  <Typography
                    sx={({ palette }) => ({
                      marginTop: 3,
                      fontSize: 18,
                      color: palette.common[100],
                    })}>
                    Receipt of your purchase
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};