import {
  mapper
} from '@shared/lib';
import {
  type PaymentMethod
} from '../model';

type MapPaymentMethod = (data: any) => PaymentMethod;
export const mapPaymentMethod: MapPaymentMethod = mapper([
  { name: 'id', from: 'id' },
  { name: 'displayName', from: 'attributes.displayName' },
  { name: 'key', from: 'attributes.key' },
  { name: 'backupPayment1', from: 'attributes.backupPayment1' },
  { name: 'backupPayment2', from: 'attributes.backupPayment2' },
  { name: 'iconSrc', from: 'attributes.icon.data.attributes.url' }
]);
