import {
  FC
} from 'react';
import {
  Typography
} from '@mui/joy';
import {
  Link
} from '@shared/ui';
import {
  selectIsPlayerVerified,
  useBuyCoinsSelector
} from '../model';

const OfficialSweepstakesRulesLink: FC = () => (
  <Link
    color='primary'
    href='/official-sweepstakes-rules'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    }}>
    Official Sweepstakes rules
  </Link>
);

const TermsConditionsLink: FC = () => (
  <Link
    color='primary'
    href='/terms-and-conditions'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }}>
    Terms & Conditions
  </Link>
);

const ContactPhoneLink: FC = () => (
  <Link
    color='primary'
    href='tel:+16469050006'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }}>
    +16469050006
  </Link>
);

export const FreePlayDisclaimer: FC = () => {
  const isPlayerVerified = useBuyCoinsSelector(selectIsPlayerVerified);
  return (
    <Typography
      sx={({ palette }) => ({
        color: palette.common[150],
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
      })}>
      {isPlayerVerified ? (
        <>
          It’s always FREE to play our Promotional Coins games. No purchase necessary!&nbsp;
          <OfficialSweepstakesRulesLink />
          &nbsp;and&nbsp;
          <TermsConditionsLink />&nbsp;apply.
          If you need help please contact our support at&nbsp;
          <ContactPhoneLink />
        </>
      ) : (
        <>
          It’s always FREE to play our Promotional Coins games. No purchase necessary!&nbsp;
          <TermsConditionsLink />&nbsp;apply.
          If you need help please contact our support at&nbsp;
          <ContactPhoneLink />
        </>
      )}
    </Typography>
  );
};