import {
  FC
} from 'react';
import {
  Box,
  Typography
} from '@mui/joy';
import {
  Button,
  ButtonProps
} from '@shared/ui';

export type PaymentMethodButtonProps = ButtonProps & {
  hint: string;
  iconSrc: Url;
  onClick?(): void;
};

export const PaymentMethodButton: FC<PaymentMethodButtonProps> = ({
  hint,
  iconSrc,
  onClick,
  ...props
}) => (
  <Button
    color='neutral'
    sx={({ breakpoints }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: '8px',
      width: 212,
      height: 156,
      paddingBlock: '24px',
      paddingInline: '16px',
      [breakpoints.down(491)]: {
        width: '100%',
      }
    })}
    onClick={onClick}
    {...props}>
    <Box
      component='img'
      loading='lazy'
      src={iconSrc}
      sx={() => ({
        maxHeight: 108,
        maxWidth: 180,
        minWidth: 114
      })}
    />
    <Typography
      sx={{
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
      }}>
      {hint}
    </Typography>
  </Button>
);