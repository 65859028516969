import {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Link,
  Stack,
} from '@mui/joy';
import {
  Button,
  Dropdown
} from '@shared/ui';
import {
  formatCurrency,
  isEmpty
} from '@shared/lib';
import {
  PAYMENT_INFO_TAG,
  baseOmegaApi
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  useGetPaymentInfoQuery,
} from '../api';
import {
  type CardSelectorItem,
  mapCardSelectorItems
} from '../lib';

export type StoredCreditCardSelectorProps = {
  amount: string;
  submitButtonLabelSlot?: ReactNode;
  onCardSelected?(card: Maybe<CardSelectorItem>): void;
  onNoStoredCards?(): void;
  onStoredCardsFound?(): void;
  onAddNewCard?(): void;
};

export const StoredCreditCardSelector: FC<StoredCreditCardSelectorProps> = ({
  amount,
  submitButtonLabelSlot,
  onAddNewCard,
  onCardSelected,
  onNoStoredCards,
  onStoredCardsFound,
}) => {
  const dispatch = useDispatch();

  const [cardItems, setCardItems] = useState<Array<CardSelectorItem>>([]);
  const [options, setOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [selectedOption, setSelectedOption] = useState<Maybe<string>>(null);
  const [showCardSelector, setShowCardSelector] = useState<boolean>(false);

  const { isFetching, data: getPaymentInfoResponse } = useGetPaymentInfoQuery({
    paymentMethods: PaymentMethodKeyEnum.WorldPayCNPCard
  });

  useEffect(() => {
    dispatch(baseOmegaApi.util.invalidateTags([PAYMENT_INFO_TAG]));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isFetching && isEmpty(getPaymentInfoResponse?.data)) {
      setShowCardSelector(false);
      onNoStoredCards?.();
    }
    // eslint-disable-next-line
  }, [isFetching])

  useEffect(() => {
    if (!isEmpty(getPaymentInfoResponse?.data)) {
      const mappedCards = mapCardSelectorItems({ data: getPaymentInfoResponse?.data }).cards;
      const mappedOptions = mappedCards.map(
        ({ id, last4Digit }) => ({ label: last4Digit, value: `${id}` })
      );

      setCardItems(mappedCards);
      setOptions(mappedOptions);
      setShowCardSelector(true);

      if (!isEmpty(mappedOptions))
        setSelectedOption(mappedOptions[0].value);

      onStoredCardsFound?.();
    }
    // eslint-disable-next-line
  }, [getPaymentInfoResponse?.data]);

  const handleSelectCard = (cardId: string): void => {
    setSelectedOption(cardId);
  };

  const handlePay = (): void => {
    setShowCardSelector(false);
    onCardSelected?.(
      cardItems.find(({ id }) => String(id) === selectedOption)
    );
  };

  const handleAddNewCard = (): void => {
    setShowCardSelector(false);
    onAddNewCard?.();
  };

  return showCardSelector && (
    <Stack
      direction='column'
      justifyContent='space-between'
      height='100%'
      sx={({ breakpoints }) => ({
        paddingTop: .2,
        [breakpoints.down(460)]: {
          paddingTop: 4.8,
        }
      })}>
      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        rowGap={5}
        sx={({ palette, breakpoints }) => ({
          paddingInline: 1,
          paddingBlockStart: 1,
          paddingBlockEnd: 1,
          borderRadius: 8,
          backgroundColor: palette.common[900],
          [breakpoints.down(460)]: {
            paddingBlockEnd: 2
          }
        })}>
        <Dropdown
          label='Choose card'
          value={selectedOption}
          onChange={handleSelectCard}
          options={options}
          inputProps={{
            root: {
              sx: {
                minHeight: 'unset',
                width: '100%',
              },
            },
            label: {
              sx: ({ breakpoints }) => ({
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                fontSize: 14,
                [breakpoints.down(460)]: {
                  fontSize: 18,
                  paddingBottom: .8
                }
              }),
            },
            select: {
              sx: {
                '&>.MuiSelect-button': {
                  fontSize: 18,
                  letterSpacing: '2.5px',
                  width: '100%',
                },
              }
            },
            option: {
              value: '',
              sx: {
                fontSize: 18,
                letterSpacing: '2.5px',
                width: '100%',
              }
            },
          }}
        />
        <Link
          color='primary'
          underline='none'
          sx={({ breakpoints }) => ({
            fontSize: 16,
            [breakpoints.down(460)]: { fontSize: 18 }
          })}
          onClick={handleAddNewCard}>
          Add New Card
        </Link>
      </Stack>
      <Button
        fullWidth
        color='primary'
        disabled={isEmpty(selectedOption)}
        onClick={handlePay}>
        {submitButtonLabelSlot
          ? submitButtonLabelSlot
          : `Pay ${formatCurrency(amount)}`}
      </Button>
    </Stack>
  );
};