import {
  createContext,
  useContext
} from 'react';

export interface GoogleAdsContextType {
  trackConversion: (transactionId?: string) => void;
}

export const GoogleAdsContext = createContext<GoogleAdsContextType>({
  trackConversion: () => { }
});

export const useGoogleAds = () => useContext(GoogleAdsContext);
