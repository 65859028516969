import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@shared/ui';
import {
  TransactionTypeWithCurrencyEnum,
  selectCurrentTransactionTypeWithCurrency,
  useTransactionFilterByTypeSelector,
  resetTransactionFilterByType,
  setCurrentTransactionTypeWithCurrency,
} from '../model';

export type TransactionFilterByTypeDropdownProps = {
  disabled?: boolean;
  onChange?: (transactionTypeWithCurrency: TransactionTypeWithCurrencyEnum) => void;
};

export const TransactionFilterByTypeDropdown: FC<TransactionFilterByTypeDropdownProps> = ({ disabled, onChange }) => {
  const dispatch = useDispatch();

  const options = useMemo(() => [
    {
      label: 'Bet with GC',
      value: TransactionTypeWithCurrencyEnum.GameBetWithGC
    },
    {
      label: 'Win with GC',
      value: TransactionTypeWithCurrencyEnum.GameWinGC
    },
    {
      label: 'Bet with SC',
      value: TransactionTypeWithCurrencyEnum.GameBetWithSC
    },
    {
      label: 'Win with SC',
      value: TransactionTypeWithCurrencyEnum.GameWinSC
    },
    {
      label: 'Redemption',
      value: TransactionTypeWithCurrencyEnum.WithdrawalWithSC
    },
    {
      label: 'Purchase',
      value: TransactionTypeWithCurrencyEnum.DepositWithSC
    },
  ], []);

  const currentTransactionTypeWithCurrency = useTransactionFilterByTypeSelector(
    selectCurrentTransactionTypeWithCurrency
  );

  useEffect(() => {
    return () => {
      dispatch(resetTransactionFilterByType());
    }
  }, [dispatch]);

  const handleSelectTransactionType = (value: unknown): void => {
    dispatch(setCurrentTransactionTypeWithCurrency(value as TransactionTypeWithCurrencyEnum));
    onChange?.(value as TransactionTypeWithCurrencyEnum);
  };

  return (
    <Dropdown
      label='Transaction type'
      value={String(currentTransactionTypeWithCurrency)}
      onChange={handleSelectTransactionType}
      disabled={disabled}
      options={options}
      inputProps={{
        root: {
          sx: ({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          }),
        },
        label: {
          sx: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
        },
      }}
    />
  );
};