import {
  ComponentType,
  Fragment,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import {
  UserRegistrationStatusEnum,
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  UserKycStatusEnum,
  selectIsAuthenticated,
  selectRegistrationStatus,
  selectUserTags,
  useSessionSelector,
  selectKycStatus,
} from '@entities/session';
import {
  PaymentMethodsSelectorModal,
  CheckoutFailedWorldPayModal,
  CheckoutSuccessWorldPayModal,
  openCheckoutFailedWorldPayModal,
  closeCheckoutFailedWorldPayModal,
  openCheckoutSuccessWorldPayModal,
  closeCheckoutSuccessWorldPayModal,
  selectActionPurpose,
  usePaymentsSelector,
  selectOpenPaymentMethodsSelectorModal,
  closePaymentMethodsSelectorModal,
  selectPaymentMethodOrderSetType,
  storeRecentlyUsedPaymentMethod,
  selectCurrentPaymentMethod,
  PaymentMethodOrderSetTypeEnum,
  type PaymentMethod,
} from '@entities/payments';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  CheckoutPaymentIQModal,
  CheckoutWorldPayModal,
  CoinPackagesModal,
  PleaseConfirmYourIdentityModal,
  useBuyCoinsSelector,
  selectOpenCheckoutWorldPayModal,
  selectOpenCheckoutPaymentIQModal,
  openCheckoutWorldPayModal,
  openCheckoutPaymentIQModal,
} from '@features/buy-coins';

export const withBuyCoinsModal = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal, openModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const userTags = useSessionSelector(selectUserTags);

    const isOpenCheckoutWorldPayModal = useBuyCoinsSelector(selectOpenCheckoutWorldPayModal);
    const isOpenCheckoutPaymentIQModal = useBuyCoinsSelector(selectOpenCheckoutPaymentIQModal);
    const isOpenPaymentMethodsSelector = usePaymentsSelector(selectOpenPaymentMethodsSelectorModal);
    const paymentMethodOrderSetType = usePaymentsSelector(selectPaymentMethodOrderSetType);
    const currentPaymentMethod = usePaymentsSelector(selectCurrentPaymentMethod);
    const isBuyActionPurpose = usePaymentsSelector(selectActionPurpose('buy'));
    const openBuyModal = useQueryParamModalVisibility('buy');

    const { sendDataToGtm } = useSendDataToGtm();
    const { trackConversion } = useGoogleAds();

    const allowToBuy = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const openCoinPackagesModal = useMemo(
      () => openBuyModal && isAuthenticated && allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    const openPleaseConfirmYourIdentityModal = useMemo(
      () => openBuyModal && isAuthenticated && !allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    const isOpenPaymentMethodsSelectorModal = useMemo(
      () => (
        isOpenPaymentMethodsSelector
        && paymentMethodOrderSetType === PaymentMethodOrderSetTypeEnum.Deposit
        && isAuthenticated
      ),
      [
        isOpenPaymentMethodsSelector,
        paymentMethodOrderSetType,
        isAuthenticated,
      ]
    );

    useEffect(() => {
      if (!isAuthenticated && openBuyModal)
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 1000
        });
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('buy');
    };

    const handleDepositStart = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_start',
        }
      })
    };

    const handleDepositSuccess = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openCheckoutSuccessWorldPayModal({
          actionPurpose: 'buy',
          result: depositResult
        }));

        if (currentPaymentMethod) {
          dispatch(storeRecentlyUsedPaymentMethod(currentPaymentMethod));
        }
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_success',
          depositAmount: depositResult?.amount,
          depositCurrency: 'USD',
          depositcount: '1',
          paymentMethod: 'WorldPay'
        }
      });
      trackConversion();
    };

    const handleDepositFail = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openCheckoutFailedWorldPayModal({
          actionPurpose: 'buy',
          result: depositResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_fail',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleCloseWorldPayCheckoutSuccessPopup = (): void => {
      dispatch(closeCheckoutSuccessWorldPayModal());
    };

    const handleCloseWorldPayCheckoutFailedPopup = (): void => {
      dispatch(closeCheckoutFailedWorldPayModal());
    };

    const handleWorldPayCheckoutTryAgain = (): void => {
      dispatch(closeCheckoutFailedWorldPayModal());
      openModal('buy');
    };

    const handleSelectPaymentMethods = (paymentMethod: PaymentMethod): void => {
      const triggerPaymentCheckoutModal = ({
        [PaymentMethodKeyEnum.WorldPayCNPCard]: () => {
          dispatch(openCheckoutWorldPayModal());
          dispatch(closePaymentMethodsSelectorModal());
        },
        [PaymentMethodKeyEnum.PaymentIQ]: () => {
          dispatch(openCheckoutPaymentIQModal());
          dispatch(closePaymentMethodsSelectorModal());
        },
        [PaymentMethodKeyEnum.Unipaas]: null
      })[paymentMethod.key];
      triggerPaymentCheckoutModal?.();
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />

        {openCoinPackagesModal && (
          <CoinPackagesModal
            open={openCoinPackagesModal}
            onClose={handleClose}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
          />
        )}

        <PleaseConfirmYourIdentityModal
          open={openPleaseConfirmYourIdentityModal ?? false}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Coin packages
            </Typography>
          )}
          onClose={handleClose}
        />

        {isOpenPaymentMethodsSelectorModal && (
          <PaymentMethodsSelectorModal
            open={isOpenPaymentMethodsSelectorModal}
            key={PaymentMethodOrderSetTypeEnum.Deposit}
            type={PaymentMethodOrderSetTypeEnum.Deposit}
            userTags={userTags}
            onSelectPaymentMethod={handleSelectPaymentMethods}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                How do you like yo pay?
              </Typography>
            )}
          />
        )}

        {isOpenCheckoutPaymentIQModal && (
          <CheckoutPaymentIQModal
            open={isOpenCheckoutPaymentIQModal}
            onDepositFail={handleDepositFail}
            onDepositStart={handleDepositStart}
            onDepositSuccess={handleDepositSuccess}
          />
        )}

        <CheckoutWorldPayModal
          open={isOpenCheckoutWorldPayModal}
          onDepositFail={handleDepositFail}
          onDepositStart={handleDepositStart}
          onDepositSuccess={handleDepositSuccess}
        />

        {isBuyActionPurpose && (
          <Fragment>
            <CheckoutSuccessWorldPayModal
              onClose={handleCloseWorldPayCheckoutSuccessPopup}
            />
            <CheckoutFailedWorldPayModal
              onClose={handleCloseWorldPayCheckoutFailedPopup}
              onTryAgain={handleWorldPayCheckoutTryAgain}
              onBackupPayment={handleSelectPaymentMethods}
            />
          </Fragment>
        )}

        <CheckoutSuccessWorldPayModal
          onClose={handleCloseWorldPayCheckoutSuccessPopup}
        />
      </Fragment>
    );
  };
