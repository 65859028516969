import {
  mapper
} from '@shared/lib';
import dayjs from 'dayjs';
import {
  formatDate
} from '@shared/lib';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  TransactionTypeEnum,
  type Transaction
} from '../model';

type MapTransaction = (data: any) => Transaction;
export const mapTransaction: MapTransaction = mapper([
  { name: 'id', from: 'id' },
  {
    name: 'dateTime',
    from: 'dateTime',
    transform: (dateTime: string) => {
      return formatDate(dayjs(dateTime, 'DD-MM-YYYY HH:mm:ss'), 'MM/DD/YY')
    },
  },
  { name: 'type', from: 'tranType' },
  {
    name: 'amount',
    from: 'amount',
    transform: (amount: number) => Number(String(amount).replace(/^-/, '')),
  },
  { name: 'gameName', from: 'gameName' },
  { name: 'paymentSubMethod', from: 'paymentSubMethod' },
  { name: 'currency', from: 'currency' },
  {
    name: 'product',
    from: ['tranType', 'paymentMethod'],
    transform: (value: string, data: any) => {
      if (
        (data.tranType === TransactionTypeEnum.Deposit || data.tranType === TransactionTypeEnum.Withdrawal) &&
        data.paymentMethod === PaymentMethodKeyEnum.WorldPayCNPCard
      ) {
        return 'Credit Card';
      }
      return '';
    },
  },
]);

type MapTransactions = (data: any) => Array<Transaction>;
export const mapTransactions: MapTransactions = (data: any): Array<Transaction> => {
  return (data as Array<any>).map((transactionData: any) => mapTransaction(transactionData));
};