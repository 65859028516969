import {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Button,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  useTheme as useMaterialTheme,
  useMediaQuery
} from '@mui/material';
import {
  InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  useGetPaymentMethodsQuery
} from '../api';
import {
  mapPaymentMethod
} from '../lib';
import {
  usePaymentsSelector,
  selectCurrentPaymentMethod,
  selectCheckoutResultWorldPay,
  selectOpenCheckoutFailedWorldPayModal,
  PaymentMethod,
  closeCheckoutFailedWorldPayModal
} from '../model';
import {
  PaymentMethodsCard
} from './payment-methods-card.component';
import {
  PaymentMethodButton
} from './payment-method-button.component';

export type CheckoutFailedWorldPayModalProps = {
  onClose?(): void;
  onTryAgain?(amount: number): void;
  onBackupPayment?(paymentMethod: PaymentMethod): void;
};

export const CheckoutFailedWorldPayModal: FC<CheckoutFailedWorldPayModalProps> = ({
  onClose,
  onTryAgain,
  onBackupPayment
}) => {
  const dispatch = useDispatch();
  const joyTheme = useJoyTheme();
  const materialTheme = useMaterialTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down(490));

  const checkoutResult = usePaymentsSelector(selectCheckoutResultWorldPay);
  const currentPaymentMethod = usePaymentsSelector(selectCurrentPaymentMethod);
  const open = usePaymentsSelector(selectOpenCheckoutFailedWorldPayModal);

  const { isLoading, refetch, data: response } = useGetPaymentMethodsQuery();

  const backupPaymentMethods: Maybe<Array<PaymentMethod>> = useMemo(() => {
    if (!response?.data?.length) return null;

    const paymentMethods = response.data
      .map(mapPaymentMethod)
      .filter(({ key }: PaymentMethod) =>
        key === currentPaymentMethod?.backupPayment1 || key === currentPaymentMethod?.backupPayment2);

    return paymentMethods.length > 0 ? paymentMethods : null;
  }, [response?.data, currentPaymentMethod?.backupPayment1, currentPaymentMethod?.backupPayment2]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleTryAgain = (): void => {
    const amount = checkoutResult?.amount
      ? Number(checkoutResult?.amount)
      : 0;
    onTryAgain?.(amount);
  };

  const handleSelectPaymentMethod = useCallback((paymentMethod: PaymentMethod) => () => {
    dispatch(closeCheckoutFailedWorldPayModal());
    onBackupPayment?.(paymentMethod);
  }, [dispatch, onBackupPayment]);

  return isLoading ? null : (
    <Modal open={open} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: 'fit-content',
          maxWidth: 488,
          backgroundColor: palette.common[475],
          [breakpoints.down(491)]: {
            height: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            width='100%'
            height='100%'>
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              sx={{
                rowGap: 2,
                marginBottom: 4
              }}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                sx={({ palette }) => ({
                  height: 78,
                  width: '100%',
                  padding: 2,
                  columnGap: 1,
                  backgroundColor: palette.common[900],
                  borderRadius: 4,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: palette.common.error,
                })}>
                <InfoOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: joyTheme.palette.common.error
                  }}
                />
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '23px',
                    color: palette.common.error,
                  })}>
                  The transaction failed. Please contact support, or try again.
                </Typography>
              </Stack>
              <Typography
                sx={({ palette }) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: 44,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: palette.common.white,
                  backgroundColor: palette.common[900],
                  borderRadius: 8,
                })}>
                {formatCurrency(checkoutResult?.amount ?? 0)}
              </Typography>

              <Stack width='100%' rowGap={2}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Card number
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.cardNumber}
                  </Typography>
                </Stack>

                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Purchase amount
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {formatCurrency(checkoutResult?.amount ?? 0)}
                  </Typography>
                </Stack>
                {checkoutResult?.referenceId && (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={({ palette }) => ({
                      paddingBlock: 1,
                      width: '100%',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 1,
                      borderBottomColor: palette.common[925],
                    })}>
                    <Typography
                      sx={({ palette }) => ({
                        color: palette.common.white,
                        fontSize: 14,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px'
                      })}>
                      Reference ID
                    </Typography>
                    <Typography
                      sx={({ palette }) => ({
                        color: palette.common.white,
                        fontSize: 14,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px'
                      })}>
                      {checkoutResult?.referenceId}
                    </Typography>
                  </Stack>
                )}
                {checkoutResult?.paymentId && (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={({ palette }) => ({
                      paddingBlock: 1,
                      width: '100%',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 1,
                      borderBottomColor: palette.common[925],
                    })}>
                    <Typography
                      sx={({ palette }) => ({
                        color: palette.common.white,
                        fontSize: 14,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px'
                      })}>
                      Payment ID
                    </Typography>
                    <Typography
                      sx={({ palette }) => ({
                        color: palette.common.white,
                        fontSize: 14,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px'
                      })}>
                      {checkoutResult?.paymentId}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack>
              {backupPaymentMethods && (
                <PaymentMethodsCard label='Have you tried using?'>
                  {backupPaymentMethods.map(backupPaymentMethod => (
                    <PaymentMethodButton
                      key={backupPaymentMethod.id}
                      hint={backupPaymentMethod.displayName}
                      iconSrc={backupPaymentMethod.iconSrc}
                      onClick={handleSelectPaymentMethod(backupPaymentMethod)}
                    />
                  ))}
                </PaymentMethodsCard>
              )}
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                sx={{
                  paddingBlock: 1,
                }}>
                <Divider
                  orientation='horizontal'
                  sx={{ width: 127 }}>
                  or
                </Divider>
              </Stack>
              <Button
                fullWidth
                onClick={handleTryAgain}>
                Try again
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};