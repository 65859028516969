import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  mapper
} from '@shared/lib';
import {
  type CardDetailsInfo,
  CardDetailsKeyEnum,
} from '../api';
import {
  maskCardDigitsIfClean
} from './mask-card-digits-if-clean';

export type CardSelectorItem = {
  id: number;
  partyId: number;
  paymentMethod: PaymentMethodKeyEnum;
  priority: number;
  last4Digit: string;
  token: string;
  expiryDate: string;
  type: 'MC' | 'DI' | 'VI';
};

type MapCardSelectorItem = (data: any) => CardSelectorItem;
const mapCardSelectorItem: MapCardSelectorItem = mapper([
  { name: 'id', from: 'id' },
  { name: 'priority', from: 'priority' },
  { name: 'partyId', from: 'partyId' },
  {
    name: 'paymentMethod',
    from: 'paymentMethods',
    transform: (paymentMethods: Array<PaymentMethodKeyEnum>) =>
      paymentMethods[0]
  },
  {
    name: 'expiryDate',
    from: 'details',
    transform: (details: Array<CardDetailsInfo>) => {
      const expiryDate = details.find(({ key }) => key === CardDetailsKeyEnum.ExpiryDate);
      return expiryDate?.value;
    }
  },
  {
    name: 'last4Digit',
    from: 'details',
    transform: (details: Array<CardDetailsInfo>) => {
      const last4DigitDetails = details.find(
        ({ key }) => key === CardDetailsKeyEnum.Last4Digit
      );
      return last4DigitDetails?.value
        ? maskCardDigitsIfClean(last4DigitDetails?.value)
        : null;
    }
  },
  {
    name: 'token',
    from: 'details',
    transform: (details: Array<CardDetailsInfo>) => {
      const tokenDetails = details.find(
        ({ key }) => key === CardDetailsKeyEnum.Token
      );
      return tokenDetails?.value ?? null;
    }
  },
  {
    name: 'type',
    from: 'details',
    transform: (details: Array<CardDetailsInfo>) => {
      const typeDetails = details.find(
        ({ key }) => key === CardDetailsKeyEnum.Type
      );
      return typeDetails?.value ?? null;
    }
  },
]);

export type MapCardSelectorItems = (data: any) => { cards: Array<CardSelectorItem> };
export const mapCardSelectorItems: MapCardSelectorItems = mapper([
  {
    name: 'cards',
    from: 'data',
    transform: (details: Array<CardDetailsInfo>) => {
      const cards = details?.map(mapCardSelectorItem) ?? [];
      const cardKeyValues = cards.reduce(
        (acc: Record<string, CardSelectorItem>, next: CardSelectorItem) =>
          acc[next.last4Digit] ? acc : { ...acc, [next.last4Digit]: next }, {}
      );
      const uniqueCards = Object.values(cardKeyValues);
      return uniqueCards;
    }
  },
]);

